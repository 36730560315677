import { computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { signalStore, type, withComputed, withMethods } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import { SecurityBase } from '@pinnakl/securities/domain';
import { withCallState, withDataService, withDevtools } from '@pinnakl/shared/signal-store';
import { SecuritiesSearchFilter, SecuritySearchResult } from '@pinnakl/shared/types';
import { AssetTypesFacadeService } from '../asset-types';
import { SecurityTypesFacadeService } from '../security-types';
import { SecuritiesApiService, SecuritiesFilter } from './securities-api.service';

const securitiesStoreKey = 'securities';
export const SecuritiesStore = signalStore(
  { providedIn: 'root' },
  withDevtools(securitiesStoreKey),
  withCallState({
    collection: securitiesStoreKey
  }),
  withEntities({
    entity: type<SecurityBase>(),
    collection: securitiesStoreKey
  }),
  withDataService<SecurityBase, SecuritiesFilter, SecuritiesApiService, 'securities'>({
    dataServiceType: SecuritiesApiService,
    filter: {},
    collection: securitiesStoreKey
  }),
  withComputed(store => {
    const securityTypes = toSignal(inject(SecurityTypesFacadeService).securityTypes$);
    const assetTypes = toSignal(inject(AssetTypesFacadeService).assetTypes$);
    return {
      selectedSecurityAssetType: computed(() => {
        if (securityTypes()?.length && store.currentSecurities()) {
          const secType = securityTypes()?.find(
            secType => secType.id === store.currentSecurities()?.secTypeId
          );
          if (secType) {
            const assetType = assetTypes()?.find(assetType => assetType.id === secType.assetTypeId);
            return assetType ?? null;
          }
        }
        return null;
      })
    };
  }),
  withMethods((store, api = inject(SecuritiesApiService)) => ({
    searchSecurities: async (params?: SecuritiesSearchFilter): Promise<SecuritySearchResult[]> => {
      return api.searchSecurities(params);
    },
    loadAffectedSecurityTradesAmount: async (securityId: number): Promise<{ affectedCount: 0 }> => {
      return api.loadAffectedSecurityTradesAmount(securityId);
    }
  }))
);
