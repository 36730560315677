import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuardCanActivateChild } from '@pinnakl/auth/providers';
import { RedirectComponent } from '@pinnakl/shared/base-components';
import { AppNames } from '@pinnakl/shared/types';

const authRoutes: Routes = [
  {
    path: 'cash-flow',
    loadChildren: () => import('../app/cash-flow/cash-flow.module').then(m => m.CashFlowModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('@pinnakl/authentication').then(m => m.SettingsModule),
    data: { projectName: AppNames.PLATFORM_WEB }
  },
  {
    path: 'user-settings',
    loadChildren: () => import('@pinnakl/user-settings/shell').then(m => m.USER_SETTINGS_ROUTES)
  },
  {
    path: 'corporate-actions',
    loadChildren: () =>
      import('./corporate-actions/corporate-actions-ui/corporate-actions-ui.module').then(
        m => m.CorporateActionsUiModule
      )
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard-ui/dashboard-ui.module').then(m => m.DashboardUiModule)
  },
  {
    path: 'help',
    loadChildren: () => import('@pinnakl/user-guide/shell').then(m => m.UserGuideShellModule)
  },
  {
    path: 'ems',
    loadChildren: () => import('@pinnakl/poems/ems/shell').then(m => m.EmsShellModule)
  },
  {
    path: 'rebalancing',
    loadChildren: () => import('@pinnakl/rebalancing/shell').then(m => m.RebalancingShellModule)
  },
  {
    path: 'oms',
    loadChildren: () => import('./oms/oms.module').then(m => m.OMSModule)
  },
  {
    path: 'pms',
    loadChildren: () => import('@pinnakl/pms/shell').then(m => m.PmsShellModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('../app/pricing/pricing.module').then(m => m.PricingModule)
  },
  {
    path: 'attribution',
    loadChildren: () =>
      import('@pinnakl/performance-attribution/shell').then(m => m.PERFORMANCE_ATTRIBUTION_ROUTES)
  },
  {
    path: 'reconciliation',
    loadChildren: () =>
      import('./reconciliation/reconciliation.module').then(m => m.ReconciliationModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import('@pinnakl/reporting').then(m => m.ReportingModule),
    data: { projectName: AppNames.PLATFORM_WEB }
  },
  {
    path: 'risk',
    loadChildren: () => import('./risk/risk-ui/risk.module').then(m => m.RiskModule)
  },
  {
    path: 'securities',
    loadChildren: () => import('@pinnakl/securities/shell').then(m => m.SECURITIES_ROUTES)
  },
  {
    path: 'stock-loan',
    loadChildren: () => import('@pinnakl/stock-loan/shell').then(m => m.STOCK_LOAN_ROUTES)
  },
  {
    path: 'shadow-nav',
    loadChildren: () =>
      import('./shadow-nav/shadow-nav-ui/shadow-nav.module').then(m => m.ShadowNAVModule)
  },
  {
    path: 'configuration',
    loadChildren: () =>
      import('@pinnakl/poems/configuration/shell').then(m => m.CONFIGURATION_ROUTES)
  },
  {
    path: 'api-playground',
    loadChildren: () => import('@pinnakl/shared-ui').then(m => m.ApiPlaygroundModule)
  },
  {
    path: 'push-notification',
    loadChildren: () => import('@pinnakl/push-notifications').then(m => m.PushNotificationUiModule)
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

const appRoutes: Routes = [
  {
    path: '',
    canActivateChild: [AuthenticatedGuardCanActivateChild],
    children: [...authRoutes]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
