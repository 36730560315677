import { SecurityMarketDetails } from '@pinnakl/securities/domain';
import { SecuritySearchResult } from '@pinnakl/shared/types';
import { SearchSecurityItem } from './security-item.model';

const colors = ['#ba68c8', '#ffb74d', '#4dd0e1', '#673ab7'];

export function mapSecuritiesData(
  securities: Array<SecuritySearchResult> | Array<SecurityMarketDetails>
): SearchSecurityItem[] {
  if (!securities || securities.length === 0) {
    return [];
  }
  let i = 0;
  let securitiesToUse: Array<SecuritySearchResult> = [];
  if (securities[0] && Object.hasOwn(securities[0], 'ticker')) {
    securitiesToUse = securities as Array<SecuritySearchResult>;
  } else if (securities[0]) {
    securitiesToUse = (securities as Array<SecurityMarketDetails>).map(
      (security: SecurityMarketDetails): SecuritySearchResult => ({
        id: security.id,
        description: security.description,
        cusip: security.cusip,
        assetType: security.assetType,
        isin: security.isin,
        countryOfIncorporationCode: security.organization.countryOfIncorporation.code,
        countryOfQuotationCode: security.market.countryOfQuotation.code,
        currency: security.primaryCurrency.code,
        securityMarketId: security.market.id,
        sedol: security.market.sedol,
        ticker: security.market.ticker,
        position: 0,
        multiplier: security.multiplier,
        secType: security.secType,
        principalFactor: security.principalFactor,
        private: security.private,
        maturity: '',
        longPosition: 0,
        shortPosition: 0
      })
    );
  } else {
    return [];
  }
  const mappedSecurities: SearchSecurityItem[] = [];
  for (const security of securitiesToUse) {
    let searchString = '';
    const sec = { ...security, color: '', searchString: ' ' } as SearchSecurityItem;
    const { ticker, cusip, assetType } = security;
    if (assetType) {
      searchString += `${assetType} - `;
    }
    if (ticker) {
      searchString += `${ticker} - `;
    }
    if (cusip) {
      searchString += `${cusip} - `;
    }
    if (i === colors.length) {
      i = 0;
    }
    sec.color = colors[i];
    i += 1;
    sec.searchString = searchString + security.description;
    mappedSecurities.push(sec);
  }
  return mappedSecurities;
}

export function getCurrentSymbol(text: string): string {
  return text.length ? text[0].toUpperCase() : '-';
}

export function addAlpha(color: string, opacity = 0.4): string {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}
