import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';

import 'highcharts/modules/export-data';
import 'highcharts/modules/exporting';
import * as XLSX from 'xlsx';
import { PnklComparisonAxisData } from './pnkl-comparison-axis-data.model';

(function (H) {
  if (XLSX && H.getOptions().exporting) {
    (H.Chart.prototype as any).downloadXLSX = function () {
      let xlsxRows = [];
      const rows = this.getDataRows(true);

      const trades = this.series[2].data.reduce(
        (acc, { text, category }) => ({
          ...acc,
          [category]: parseInt(text.slice(16))
        }),
        {}
      );

      xlsxRows = rows.slice(2).map(row => {
        const formattedRow = {};
        formattedRow[rows[0][0]] = row[0];
        formattedRow[rows[0][1]] = row[1];
        formattedRow[rows[0][2]] = row[2];
        formattedRow[rows[0][3]] = trades[row['x']];
        return formattedRow;
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsxRows);

      ws['!cols'] = Object.keys(xlsxRows[0]).map(() => ({
        wpx: 100
      }));

      if (xlsxRows.length) {
        Object.keys(xlsxRows[0])
          .filter((_, i) => i !== 0)
          .forEach((_, i) => {
            const column = String.fromCharCode(i + 2 + 64);
            for (let j = 2; j <= xlsxRows.length + 1; j++) {
              ws[`${column}${j}`] = {
                ...ws[`${column}${j}`],
                z: '#,##0.00'
              };
            }
          });
      }
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'Export.xlsx');
    };

    // Default lang string, overridable in i18n options
    (H.getOptions().lang as any).downloadXLSX = 'Download XLSX';

    // Add the menu item handler
    H.getOptions().exporting.menuItemDefinitions.downloadXLSX = {
      textKey: 'downloadXLSX',
      onclick: function () {
        (this as any).downloadXLSX();
      }
    };

    // Replace the menu item
    const menuItems = H.getOptions().exporting.buttons.contextButton.menuItems;
    menuItems[menuItems.indexOf('downloadXLS')] = 'downloadXLSX';
  }
})(Highcharts);

@Component({
  selector: 'pinnakl-simple-comparison-chart',
  templateUrl: './pnkl-comparison-chart.component.html',
  styleUrls: ['./pnkl-comparison-chart.component.scss']
})
export class PnklSimpleComparisonChartComponent implements OnChanges {
  @Input() leftAxisData?: PnklComparisonAxisData[];
  @Input() rightAxisData?: PnklComparisonAxisData[];
  @Input() leftAxisTitle: string;
  @Input() rightAxisTitle: string;

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'stockChart';
  chartOptions: Highcharts.Options;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['leftAxisData'] || changes['rightAxisData']) {
      this.chartOptions = this.getChartOptions();
    }
  }

  getChartOptions(): Highcharts.Options {
    return {
      chart: {
        renderTo: 'container',
        alignTicks: false
      },
      rangeSelector: {
        selected: 1
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          shadow: false
        }
      },
      yAxis: [
        {
          title: {
            text: this.leftAxisTitle
          },
          color: 'red',
          lineWidth: 2,
          opposite: false
        },
        {
          title: {
            text: this.rightAxisTitle
          },
          labels: {
            format: '${value}'
          },
          lineWidth: 2,
          opposite: true
        }
      ],
      legend: {
        enabled: true
      },
      series: [
        {
          type: 'column',
          name: this.leftAxisTitle,
          id: 'leftAxisSeries',
          data: this.leftAxisData.map(({ date, value }) => [date.getTime(), value]),
          color: 'rgb(204, 168, 56)',
          crisp: false
          // dataGrouping: {
          //   // enabled: true,
          //   // approximation: 'sum',
          //   units: [
          //     [
          //       'week', // unit name
          //       [1] // allowed multiples
          //     ],
          //     ['month', [1, 2, 3, 4, 6]]
          //   ]
          // }
        },
        {
          type: 'line',
          name: this.rightAxisTitle,
          id: 'rightAxisSeries',
          yAxis: 1,
          color: 'rgb(255, 99, 88)',
          data: this.rightAxisData.map(({ date, value }) => [date.getTime(), value]),
          tooltip: {
            valuePrefix: '$'
          }
          // dataGrouping: {
          //   enabled: true,
          //   units: [
          //     [
          //       'week', // unit name
          //       [1] // allowed multiples
          //     ],
          //     ['month', [1, 2, 3, 4, 6]]
          //   ],
          //   // approximation: 'average'
          // }
        },
        {
          type: 'flags',
          name: 'Trades',
          includeInDataExport: true,
          allowOverlapX: true,
          data: this.leftAxisData
            .filter(({ flagQuantity }) => flagQuantity)
            .map(({ date, flagQuantity }) => ({
              x: date.getTime(),
              text: `Trade quantity: ${flagQuantity}`
            })),
          fillColor: 'red',
          color: 'red',
          style: { color: 'red', fontSize: '2px' },
          onSeries: 'leftAxisSeries',
          shape: 'circlepin',
          y: -10,
          width: 3,
          height: 3
        }
      ],
      exporting: {
        csv: {
          dateFormat: '%m-%d-%Y'
        },
        useMultiLevelHeaders: false,
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadXLSX']
          }
        }
      }
    };
  }
}
