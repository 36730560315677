<prime-multi-select
  (onChange)="onChange.emit($event)"
  *ngIf="formControl"
  [appendTo]="appendTo"
  [filter]="filter"
  [formControl]="formControl"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [options]="options"
  [overlayOptions]="overlayOptions"
  [panelStyleClass]="'account-multi-selector-panel'"
  [panelStyle]="panelStyle"
  [placeholder]="placeholder"
  [rounded]="rounded"
  [showClear]="showClear"
  [size]="size"
  [styleClass]="styleClass"
  class="account-multi-selector"
  display="chip"
  scrollHeight="220px"
>
  <ng-template #filterTemplate>
    <div
      (click)="showToggleAll && !selectionLimit && primeMultiSelect?.onToggleAll?.($event)"
      (keydown)="
        showToggleAll && !selectionLimit && primeMultiSelect?.onHeaderCheckboxKeyDown?.($event)
      "
      class="header-content-container w-100 flex align-items-center"
    >
      <div
        *ngIf="showToggleAll && !selectionLimit"
        [ngClass]="{ 'p-checkbox-disabled': disabled }"
        class="p-checkbox p-component"
      >
        <div
          [attr.data-p-hidden-accessible]="true"
          class="p-hidden-accessible"
        >
          <input
            #headerCheckbox
            (blur)="primeMultiSelect?.onHeaderCheckboxBlur()"
            (focus)="primeMultiSelect?.onHeaderCheckboxFocus()"
            [attr.aria-label]="primeMultiSelect?.toggleAllAriaLabel"
            [attr.checked]="primeMultiSelect?.allSelected()"
            [disabled]="disabled"
            [readonly]="readonly"
            id="selectAll"
            type="checkbox"
          />
        </div>
        <div
          [attr.aria-checked]="primeMultiSelect?.allSelected()"
          [ngClass]="{
            'p-highlight': primeMultiSelect?.allSelected(),
            'p-focus': primeMultiSelect?.headerCheckboxFocus,
            'p-disabled': primeMultiSelect?.disabled
          }"
          class="p-checkbox-box"
          role="checkbox"
        >
          <ng-container *ngIf="primeMultiSelect?.allSelected()">
            <CheckIcon
              [attr.aria-hidden]="true"
              [styleClass]="'p-checkbox-icon'"
            />
          </ng-container>
        </div>
      </div>
      <label
        class="select-label cursor-pointer"
        for="selectAll"
      >
        {{ primeMultiSelect?.allSelected() ? 'Deselect All' : 'Select All' }}
      </label>
    </div>
  </ng-template>
  <ng-template #removetokeniconTemplate>
    <i
      [inlineSVG]="'sprites.svg#close'"
      [ngStyle]="{ width: '8px', height: '8px' }"
      class="flex align-items-center justify-content-center"
    ></i>
  </ng-template>
</prime-multi-select>
