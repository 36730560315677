import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  inject,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SecuritiesStore } from '@pinnakl/securities/data-access';
import { SecuritySearchResult } from '@pinnakl/shared/types';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { fromEvent, of } from 'rxjs';
import { debounceTime, mergeMap, skip, tap } from 'rxjs/operators';
import { SearchSecurityItem, SecurityItemsComponent } from '../security-items';

// eslint-disable-next-line no-shadow
enum SearchFilters {
  All = 'All',
  Equities = 'Equities',
  Option = 'Options',
  Future = 'Futures',
  Bonds = 'Bonds',
  Currency = 'Currency',
  Swaps = 'Swaps'
}

@UntilDestroy()
@Component({
  selector: 'pnkl-wide-search',
  templateUrl: './wide-search.component.html',
  styleUrls: ['./wide-search.component.scss']
})
export class WideSearchComponent implements AfterViewInit {
  private readonly assetsTypeMap = new Map<string, string>();
  private readonly securitiesStore = inject(SecuritiesStore);
  @ViewChild('searchField', { read: ElementRef }) searchField: ElementRef;
  @ViewChild('securityItemsList') securityItemsList: SecurityItemsComponent;
  searchForm = new UntypedFormGroup({
    searchField: new UntypedFormControl('', Validators.required)
  });
  isLoading = false;
  selected = SearchFilters.All;
  tabList = [
    SearchFilters.All,
    SearchFilters.Equities,
    SearchFilters.Option,
    SearchFilters.Future,
    SearchFilters.Bonds,
    SearchFilters.Currency,
    SearchFilters.Swaps
  ];
  securityList: SecuritySearchResult[];

  constructor(private readonly dynamicDialogRef: DynamicDialogRef) {
    this.initializeAssetsTypeMap();
    this.initializeFormSubscription();
  }

  ngAfterViewInit(): void {
    return;
    // this.listenOnSearchInputFocus();
  }

  itemSelected(item: SearchFilters): void {
    this.selected = item;
    // trigger a new endpoint call to get data with new filter
    const control = this.getControl();
    control.setValue(control.value, { emitEvent: true });
  }

  searchFieldIsEmpty(): boolean {
    const control = this.getControl();
    return !control.value || control.value.trim().length === 0;
  }

  itemClicked(item: SearchSecurityItem): void {
    this.dynamicDialogRef.close({ event: item });
  }

  closeModal(): void {
    this.dynamicDialogRef.close({ event: null });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent): void {
    const keypressHandlers = {
      Escape: () => {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.closeModal();
      },
      Backspace: () => {
        this.searchField.nativeElement.focus();
      },
      Tab: () => {
        if (this.securityItemsList.activeItemIndex >= 0) {
          $event.preventDefault();
          this.searchField.nativeElement.focus();
        }
      }
    };

    if (keypressHandlers[$event.code]) {
      keypressHandlers[$event.code]();
    }
  }

  private initializeAssetsTypeMap(): void {
    this.assetsTypeMap.set(SearchFilters.All, '');
    this.assetsTypeMap.set(SearchFilters.Option, 'Option');
    this.assetsTypeMap.set(SearchFilters.Future, 'Future');
    this.assetsTypeMap.set(SearchFilters.Equities, 'Equity');
    this.assetsTypeMap.set(SearchFilters.Bonds, 'Bond');
    this.assetsTypeMap.set(SearchFilters.Swaps, 'trs');
    this.assetsTypeMap.set(SearchFilters.Currency, 'crncy');
  }

  private initializeFormSubscription(): void {
    this.searchForm.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => (this.isLoading = true)),
        debounceTime(550),
        mergeMap((v: { searchField: string }) =>
          v.searchField.length >= 2
            ? this.securitiesStore.searchSecurities({
                searchString: v.searchField,
                assetType: this.getAssetType()
              })
            : of([] as SecuritySearchResult[])
        )
      )
      .subscribe(list => {
        this.securityList = list;
        this.isLoading = false;
      });
  }

  private getAssetType(): string {
    const item = this.tabList.find(el => this.selected === el);
    return this.assetsTypeMap.get(item).toLowerCase();
  }

  private getControl(): AbstractControl {
    return this.searchForm.controls.searchField;
  }

  private listenOnSearchInputFocus(): void {
    fromEvent(this.searchField.nativeElement, 'focus')
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(() => this.securityItemsList?.resetIndexOnBlur());
  }
}
