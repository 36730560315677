import {
  AfterViewInit,
  Component,
  DestroyRef,
  effect,
  HostListener,
  inject,
  Inject,
  Injector,
  OnInit,
  Self,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event as RouterEvent
} from '@angular/router';
import { Store } from '@ngrx/store';
import { ClearStore } from '@pinnakl/app-state';
import { DrawerControllerService } from '@pinnakl/atdl';
import { AuthService } from '@pinnakl/auth/providers';
import { AuthPopupService } from '@pinnakl/authentication';
import { CurrentUserStore } from '@pinnakl/core/data-providers';
import { ENVIRONMENT_SERVICE, EnvironmentService, EnvNames } from '@pinnakl/core/environment';
import { AgGridLicenseService } from '@pinnakl/grid/shared';
import { AumFacadeService } from '@pinnakl/poems/aum/data-access';
import { PriceEventsStreamFacadeService } from '@pinnakl/poems/streams/price/data-access';
import {
  NavigationService,
  PinnaklSpinnerService,
  PinnaklUIToastMessage
} from '@pinnakl/shared/util-providers';
import { Observable, take } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { NavBarService } from './nav.bar.service';

@Component({
  selector: 'pnkl-frontend-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NavBarService]
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly currentUserStore = inject(CurrentUserStore);
  private readonly navigationService: NavigationService = inject(NavigationService);
  includeTesting = false;
  menuOpen = false;
  userAvailable = false;
  isNotProduction = true;
  isNotProdEnv = true;
  version$: Observable<string | null> = this.authService.version$;
  currentUser = this.currentUserStore.currentUser;

  constructor(
    @Self() private readonly navBarService: NavBarService,
    private readonly authService: AuthService,
    private readonly authPopupService: AuthPopupService,
    private readonly agGridLicenceService: AgGridLicenseService,
    private readonly router: Router,
    private readonly toastr: PinnaklUIToastMessage,
    private readonly spinner: PinnaklSpinnerService,
    private readonly title: Title,
    private readonly drawerController: DrawerControllerService,
    private readonly viewContainerRef: ViewContainerRef,
    @Inject(ENVIRONMENT_SERVICE) private readonly envService: EnvironmentService,
    private readonly aumFacadeService: AumFacadeService,
    private readonly priceEventsStreamFacadeService: PriceEventsStreamFacadeService,
    private readonly store: Store<any>
  ) {
    this.currentUserStore.removeUser();
    this.authService.init();
    this.includeTesting = this.envService.get('includeTesting');
    this.isNotProduction = !this.envService.get('production');
    this.isNotProdEnv = this.envService.get('envName') !== EnvNames.prod;
  }

  ngOnInit(): void {
    this.navigationService.startSaveHistory().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.drawerController.initWrapper(this.viewContainerRef);
    const effectRef = effect(
      () => {
        const currentUserInfo = this.currentUserStore.currentUserInfo();
        if (currentUserInfo) {
          this.userAvailable = true;
          if (currentUserInfo.user.passwordResetRequired) this.openChangePasswordModal();
          effectRef.destroy();
        }
      },
      {
        injector: this.injector,
        manualCleanup: true
      }
    );
  }

  ngAfterViewInit(): void {
    this.agGridLicenceService.initialize();
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  searchModalOpen(): void {
    this.navBarService.searchModalOpen();
  }

  goToUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  logout(): void {
    this.spinner.spin();
    this.router.navigateByUrl('/redirect').then(() => {
      if (this.authService.logout()) {
        this.store.dispatch(ClearStore());
      }
    });
  }

  showIncompleteScreens(): boolean {
    let show = false;
    const user = this.currentUserStore.currentUser();
    if (user && (user.clientId === 2 || user.clientId === 3)) {
      show = true;
    }
    return show;
  }

  // TODO: remove this logic?
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.spinner.spin();
    }
    if (event instanceof NavigationEnd) {
      this.spinner.stop();
      this.changeTitle(event);
    }
    if (event instanceof NavigationCancel) {
      this.spinner.stop();
    }
    if (event instanceof NavigationError) {
      this.toastr.error('An unexpected error occurred');
      this.spinner.stop();
    }
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  async navigateToUserSettings(): Promise<void> {
    if (this.currentUserStore.currentUserInfo()) {
      await this.router.navigate(['/user-settings']);
    } else {
      this.toastr.warning('Current user information is missing');
    }
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilityChangeHandler(): void {
    // If logout was clicked on another tab
    if (!document.hidden && !this.currentUserStore.currentUserInfo()?.token) {
      this.authService.localLogout();
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.code === 'KeyS') {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      this.navBarService.searchModalOpen();
      this.aumFacadeService.loadAums();
      this.authService
        .getAccessToken()
        .pipe(take(1))
        .subscribe(token => {
          this.priceEventsStreamFacadeService.subscribeToStream(token);
        });
    }
  }

  openChangePasswordModal(): void {
    this.authPopupService
      .openChangePasswordModal()
      .afterClosed()
      .pipe(
        tap(() => this.spinner.spin()),
        switchMap(password => this.currentUserStore.updateUser(password))
      )
      .subscribe(() => {
        this.authService.passwordResetDone();
        this.spinner.stop();
        this.toastr.success('Password changed successfully!');
      });
  }

  private changeTitle(event: NavigationEnd): void {
    const urlToTitle = {
      dashboard: 'dashboard',
      oms: 'OMS',
      ems: 'EMS',
      pms: 'PMS',
      pricing: 'Pricing',
      risk: 'Risk',
      reconciliation: 'Reconciliation',
      pnl: 'P&L',
      reporting: 'Reporting',
      'shadow-nav': 'Shadow nav',
      securities: 'Securities',
      testing: 'testing',
      rebalancing: 'Rebalancing',
      'cash-flow': 'Cash flow',
      'stock-loan': 'Stock loan',
      'corporate-actions': 'Corporate actions',
      'api-playground': 'api playground'
    };
    let title = 'Pinnakl';
    const urlParts = event.url.split('/');
    if (urlParts.length > 1 && Object.keys(urlToTitle).includes(urlParts[1])) {
      title = `Pinnakl - ${urlToTitle[urlParts[1]]}`;
    }
    this.title.setTitle(title);
  }
}
